/**
 * 学科能力测评
 */

import { Service } from '@/utils/Service'

// 查询这些科目是否都做过题了
export function querySubjectDone (data) {
  return Service({
    url: '/subjectability/querysubject/',
    data: data
  })
}

// 查询某科的所有题目
export function querySubjectQues (data) {
  return Service({
    url: '/subjectability/subjectProblems/',
    data: data
  })
}

// 将某科做完的内容进行保存
export function postSubjectAns (data) {
  return Service({
    url: '/subjectability/postsubjectans/',
    data: data
  })
}

// 查询报告内容
export function querySubjectResult (data) {
  return Service({
    url: '/subjectability/getsubjectans/',
    data: data
  })
}
