<template>
  <div class="main">
    <div class="main-title" style="padding: 0">{{ title }}</div>
    <div class="query-info-wrapper">
      <div class="questionnaire" v-if="!agreementAgreed">
        <p>
          您好！欢迎使用灯塔智能生涯规划与数据分析系统！在使用系统前，您需要先了解关于本系统的说明。
        </p>
        <p>
          A)
          本网站含有的《专业定位测评报告》，《学科能力测评报告》，《选科规划报告》是基于公开权威测评量表以及学生的心理特征与学科能力产生的。它们基于客观事实以及智能算法得到，可帮助学生进行高效选科，并给出适合学生的大学专业与学校建议。
        </p>
        <p>
          B)
          请在相对安静无打扰的环境进行以上测评，以确保得到高质量可靠的测评结果，否则可能影响测评的准确性。
        </p>
        <p>
          C)
          本网站含有的测评结果与相关报告可帮助学生进行高效选科，但它们本质上是高效决策工具以及决策辅助手段，选科与选择专业的最终决定权是学生的，这些报告不对学生的最终选择结果负责。
        </p>
        <p>
          D)
          本网站包含的测评以及测评报告中的部分图片，数据以及文字信息来自互联网公开资料以及权威部门授权。不排除个别的数据信息源不够准确的可能，本网站不对第三方错误信息负责，本网站的所有报告解释权归测评系统开发者所有。
        </p>
        <p>
          E)
          《专业定位测评报告》基于心理学权威量表以及专业智能算法，可以比较客观地反应测评者的学科倾向。由于报告基于客观事实，呈现的结果可能与测评者主观自我认知不完全一致。
        </p>
        <p>
          F)
          《学科能力测评报告》基于部分高考真题，并通过自主开发的测评算法得到。它反应学生各个学科的客观应试能力，而《专业定位测评报告》中的“优势学科报告”主要反应学生对学科的主观喜好与自我认定的能力，这两者有一定联系，但也存在区别。
        </p>
        <div class="button-wrapper">
          <button
            class="lj-btn lj-btn-third"
            @click="onDisagreeBtnClick"
            style="margin-right: 1rem"
          >
            不同意
          </button>
          <button
            class="lj-btn lj-btn-primary"
            @click="onAgreeBtnClick"
            style="margin-left: 1rem"
          >
            同意
          </button>
        </div>
      </div>
      <div class="questionnaire" v-else>
        <p>点击下方学科按钮，开始测评</p>
        <div class="evaluation-wrapper">
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('chinese')"
            :class="resultStatus.chinese ? 'lj-btn-secondary' : 'lj-btn-third'"
          >
            语文（{{
              resultStatus.chinese
                ? '已完成测试，得分为' + resultStatus.chinese + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('math')"
            :class="resultStatus.math ? 'lj-btn-secondary' : 'lj-btn-third'"
          >
            数学（{{
              resultStatus.math
                ? '已完成测试，得分为' + resultStatus.math + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('english')"
            :class="resultStatus.english ? 'lj-btn-secondary' : 'lj-btn-third'"
          >
            外语（{{
              resultStatus.english
                ? '已完成测试，得分为' + resultStatus.english + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('physics')"
            :class="resultStatus.physics ? 'lj-btn-secondary' : 'lj-btn-third'"
          >
            物理（{{
              resultStatus.physics
                ? '已完成测试，得分为' + resultStatus.physics + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('chemistry')"
            :class="
              resultStatus.chemistry ? 'lj-btn-secondary' : 'lj-btn-third'
            "
          >
            化学（{{
              resultStatus.chemistry
                ? '已完成测试，得分为' + resultStatus.chemistry + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('biology')"
            :class="resultStatus.biology ? 'lj-btn-secondary' : 'lj-btn-third'"
          >
            生物（{{
              resultStatus.biology
                ? '已完成测试，得分为' + resultStatus.biology + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('politics')"
            :class="resultStatus.politics ? 'lj-btn-secondary' : 'lj-btn-third'"
          >
            政治（{{
              resultStatus.politics
                ? '已完成测试，得分为' + resultStatus.politics + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('history')"
            :class="resultStatus.history ? 'lj-btn-secondary' : 'lj-btn-third'"
          >
            历史（{{
              resultStatus.history
                ? '已完成测试，得分为' + resultStatus.history + '分'
                : '未完成测试'
            }}）
          </button>
          <button
            class="lj-btn lj-btn-block evaluation-item"
            @click="onEvaluationClick('geography')"
            :class="
              resultStatus.geography ? 'lj-btn-secondary' : 'lj-btn-third'
            "
          >
            地理（{{
              resultStatus.geography
                ? '已完成测试，得分为' + resultStatus.geography + '分'
                : '未完成测试'
            }}）
          </button>
        </div>
        <div class="button-wrapper">
          <button
            class="lj-btn lj-btn-primary lj-btn-block"
            style="margin: 0 10%"
            @click="onResultClick"
          >
            查看结果
          </button>
        </div>

        <div style="padding-top: 30px" class="button-wrapper">
          <button
            class="lj-btn lj-btn-third lj-btn-block main-button"
            @click="jumpPage('/')"
          >
            返回首页
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { judgeMobile } from './../../../utils/judgeMobile'
import { infoquery, agreeliability } from './../../../api/userinfo'
import { querySubjectDone, querySubjectQues } from './../../../api/subject'

export default {
  data: function () {
    return {
      agreementAgreed: false,

      resultStatus: {
        // null表示未完成测试,剩下的分数表示完成测试
        chinese: null,
        math: null,
        english: null,
        physics: null,
        chemistry: null,
        biology: null,
        politics: null,
        history: null,
        geography: null
      }
    }
  },
  computed: {
    title: function () {
      return this.agreementAgreed ? '测评导航' : '产品说明'
    },
    allDone: function () {
      return Object.values(this.resultStatus).every(function (e) {
        return !!e
      })
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },

    onDisagreeBtnClick: function () {
      this.jumpPage('/')
    },
    onAgreeBtnClick: function () {
      let that = this
      agreeliability({
        agreesubjectability: 1
      }).then(res => {
        if (res.rescode == 200) {
          that.agreementAgreed = true
          querySubjectDone({}).then(res => {
            if (res.rescode == 200) {
              that.resultStatus = res.resultStatus
            }
          })
        }
      })
    },
    onEvaluationClick: function (e) {
      if (this.resultStatus[e] == null) {
        querySubjectQues({
          subject: e
        }).then(res => {
          if (res.rescode == 200) {
            console.log(res)
            this.$store.commit('Think/SaveProblems', res.problems)
            this.$router.push({ path: '/HomeTestProblem?subject=' + e })
          }
        })
      }
    },
    onResultClick: function (e) {
      if (this.judgeDoneQues()) {
        this.$router.push({ path: '/HomeTestResult' })
      } else {
        this.$message.error('您还未进行测评')
      }
    },
    judgeDoneQues: function () {
      let ans = false
      Object.entries(this.resultStatus).forEach(entry => {
        const [key, value] = entry
        if (value !== null) {
          ans = true
        }
      })
      return ans
    }
  },
  mounted: function () {
    let that = this
    infoquery({}).then(res => {
      if (res.rescode == 200) {
        that.agreementAgreed = res.agreesubjectability
        if (that.agreementAgreed) {
          querySubjectDone({}).then(res => {
            if (res.rescode == 200) {
              that.resultStatus = res.resultStatus
            }
          })
        }
      }
    })

    if (this.isMobile === null) {
      if (window.location.href.indexOf('#reloaded') == -1) {
        window.location.href = window.location.href + '#reloaded'
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="less"></style>
